global.$ = global.jQuery = require('jquery');
global._ = require('underscore');
global.Modernizr = require('modernizr');
global.svg4everybody = require('svg4everybody');

require('jquery.cookie');
require('cookieCuttr/jquery.cookiecuttr.js');
require('jquery-shorten');
require('jquery-match-height');
require('jquery-validation');

require('bootstrap-sass');

require('bootstrap-datetime-picker');

require('scrollup/dist/jquery.scrollUp.js');

require('select2');

require('redcurrents-bentokit/src/javascripts/compat.js');
require('redcurrents-bentokit/src/javascripts/doubleburger-menu.js');
require('redcurrents-bentokit/src/javascripts/collapse-extended.js');
require('redcurrents-bentokit/src/javascripts/collapse-scrollto.js');
require('redcurrents-bentokit/src/javascripts/dropdown-submenu.js');
require('redcurrents-bentokit/src/javascripts/front/_attachments.js');
require('redcurrents-bentokit/src/javascripts/front/_cookies.js');
require('redcurrents-bentokit/src/javascripts/front/_event.js');
require('redcurrents-bentokit/src/javascripts/front/_expert_files.js');
require('redcurrents-bentokit/src/javascripts/front/_expert_spokespersons.js');
require('redcurrents-bentokit/src/javascripts/front/_publication_collapses.js');
require('redcurrents-bentokit/src/javascripts/front/_publication_responsive.js');
require('redcurrents-bentokit/src/javascripts/front/_widget_spokespersons.js');
require('redcurrents-bentokit/src/javascripts/front/_widget_webidentities.js');

require('./media--all.scss');

(function($) {
  function toolbarSticky() {
    $(window).scroll(function () {
      if (parseInt($(window).scrollTop()) >= 155) {
        $('#menu').addClass('is-fixed');
      } else {
        $('#menu').removeClass('is-fixed');
      }
    });
  }

  function scrollUp() {
    // Display the page header when scrolling down.
    jQuery.scrollUp({
        scrollName: 'scrollUp',      // Element ID
        scrollDistance: 155,         // Distance from top/bottom before showing element (px)
        scrollFrom: 'top',           // 'top' or 'bottom'
        scrollSpeed: 300,            // Speed back to top (ms)
        easingType: 'linear',        // Scroll to top easing (see http://easings.net/)
        animation: 'fade',           // Fade, slide, none
        animationSpeed: 200,         // Animation speed (ms)
        scrollTrigger: false,        // Set a custom triggering element. Can be an HTML string or jQuery object
        scrollTarget: false,         // Set a custom target element for scrolling to. Can be element or number
        scrollText: 'Haut de page', // Text for element, can contain HTML
        scrollTitle: false,          // Set a custom <a> title if required.
        scrollImg: false,            // Set true to use image
        activeOverlay: false,        // Set CSS color to display scrollUp active point, e.g '#00FFFF'
        zIndex: 2147483647,          // Z-Index for the overlay
    });
  }

  $(function() {
    toolbarSticky();
    scrollUp();

    /**
     * MATCH HEIGHT.
     * - Make sure Bootstrap 3 grid does not explode because of various thumbnail/col-* heights.
     */
    $('.match-height .thumbnail.grid').not('.file-icon').matchHeight({
      byRow: false
    });
    $('.js-match-height .card').matchHeight();
    $('.wizasset-format-dam .file-icon .resource-wrapper').matchHeight({
      byRow: false
    });

    // Init jQuery Validate
    if ('function' === typeof $.fn.validate) {
      $('.js-contact-form').validate();
    }

    // svg4everybody Polyfill <use> elements for External Content.
    svg4everybody({
      'attributeName': 'data-href',
      'polyfill': true,
    });
  });
}(jQuery));
